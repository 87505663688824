import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "medipass-bridge",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#medipass-bridge",
        "aria-label": "medipass bridge permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Medipass Bridge`}</h1>
    <p>{`Medipass Bridge is an application and background service for Windows, Mac and Linux computers that integrates HICAPS terminals with desktop or cloud based applications. The software replaces HICAPS previous integration software called HICAPS Connect. All `}<strong parentName="p">{`HICAPS terminal`}</strong>{` and `}<strong parentName="p">{`patient-funded terminal`}</strong>{` funding types require at least one HICAPS terminal and Medipass Bridge software for each enabled practice.`}</p>
    <p>{`Transactions processed via the Transaction SDK are passed to Bridge where a terminal is used/required to complete a transaction.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/terminal1.png",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/images/terminal2.png",
        "alt": null
      }}></img></p>
    <p>{`Reference `}<strong parentName="p">{`Medipass Bridge Documentation`}</strong>{` for details over installation and configuration.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      